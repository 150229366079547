<template>
  <v-app>
    <v-app-bar app color="#F6F6F6" light elevation="0" height="100">
      <router-link>
      </router-link>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
  }),
};
</script>